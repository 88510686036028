<template>
  <div class="forgot-container">
    <div class="display">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="content">
      <Input type="password" v-model="password" style="margin-bottom: 24px" label="Nova senha"></Input>
      <Input type="password" v-model="confirmPassword" style="margin-bottom: 99px" label="Confirme nova senha"></Input>

      <Button :onClick="setPassword" label="Confirmar" :disabled="passwordCompare"></Button>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'

import Input from "../common/Input/Input.vue";
import Button from "../common/Button/ButtonFilled.vue";
import AuthService from "../../services/auth"
import { mapActions } from "vuex";

export default {
  props: ["component", "email"],
  components: {
    Input,
    Button,
  },
  data(){
      return {
          password: '',
          confirmPassword: ''
      }
  },
  validations: {
    password: { required },
    confirmPassword: { required, sameAsPassword: sameAs('password')}
  },
  methods: {
    ...mapActions({
      setIsLoading: "setIsLoading",
    }),
      setPassword(){
          this.$v.$touch()

          if(this.$v.$invalid) {
            this.$toast.error("Verifique as senha digitadas")
            return
          }
          this.setIsLoading(true)
          AuthService.changePassword({senha:this.password, email:this.email}).then(()=>{
            this.$toast.success("Nova senha redefinida com sucesso")
            this.setIsLoading(false)
            this.$router.push('/login')
          }).catch(()=>{
            this.$toast.error("Houve um erro ao tentar redefinir a senha")
            this.setIsLoading(false)
          })
      }
  },
  computed: {
      passwordCompare() {
          return !((this.password && this.confirmPassword) && (this.password === this.confirmPassword))
      }
  },
};
</script>
<style scoped>
img {
  width: 113px;
  height: 118px;
}

.digits {
  display: flex;
}
</style>