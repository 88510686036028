<template>
  <div class="forgot-container">
    <div class="display">
      <h5 class="header">Confirme o código</h5>

      <p class="text">
        Digite os 4 digitos enviados para o e-mail {{email}}
      </p>
    </div>
    <div class="content">
      <div class="digits">
        <Input v-model="code[0]" type="text" maxlength="1"></Input>
        <Input v-model="code[1]" type="text" maxlength="1"></Input>
        <Input v-model="code[2]" type="text" maxlength="1"></Input>
        <Input v-model="code[3]" type="text" maxlength="1"></Input>
      </div>
      <Links to="/recuperar-senha" label="Não recebeu? Reenvie o código"></Links>
    </div>
  </div>
</template>

<script>
import Input from "../common/Input/Input.vue";
import Links from "../common/Links/Links.vue"

export default {
  props: ["component", "email"],
  components: {
    Input,
    Links,
  },
  data() {
      return {
          code: []
      }
  },
  computed: {
      joinCode(){
          return this.code.join('');
      }
  },
  watch: {
      joinCode: function() {
        if(this.joinCode.length === 4){
            this.$emit('changeComponent', {page:'SetNewPassword', code:this.joinCode.toUpperCase()})
        }
      }
  }
};
</script>
<style scoped>
.digits {
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
}

.digits .input-wrapper {
  width: 70px;
  height: 64px;
  margin-left: 16px;
}

.digits .input-wrapper:first-child {
  margin-left: 0px;
}

.forgot-container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>