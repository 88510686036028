<template>
  <div class="forgot-container">
    <div class="display">
      <h5 class="header">Esqueci minha senha</h5>

      <p class="text">
        Digite o e-mail usado no cadastro e nós enviaremos o código para
        validação
      </p>
    </div>
    <div class="content">
      <Input
        v-model="email"
        type="email"
        :error="$v.email.$error"
        @errorStatus="emailError = false"
        style="margin-bottom: 179px"
        label="E-mail"
      ></Input>

      <Button
        :disabled="!email"
        :onClick="sendEmail"
        label="Enviar"
      ></Button>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

import Input from "../common/Input/Input.vue";
import Button from "../common/Button/ButtonFilled.vue";
export default {
  props: ["component"],
  components: {
    Input,
    Button,
  },
  data() {
    return {
      email: "",
      emailError: false,
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    sendEmail() {
      
      this.$v.$touch()

      if(this.$v.$invalid) {
        return ''
      }

      this.$emit('changeComponent', {page:'ReceiveCode', email:this.email})
    },
  },
};
</script>

<style scoped>
</style>