import api from '../plugins/axios'

const routePath = "auth"

export default {
    async sendRecoverCode(recover){
        return await api.post(routePath+"/recuperar-senha", recover)
    },
    async changePassword(changePassword){
        return await api.post(routePath+"/recuperar-senha/confirmar", changePassword)
    },
    async confirmRecoverCode(recover){
        return await api.post(routePath+"/confirmar-codigo", recover)
    }
}