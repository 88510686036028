<template>
      <div class="app-container">
        <component 
          :is="ForgotPass()" 
          :component="component" 
          :email="email"
          @changeComponent="setComponent">
        </component>
      </div>
</template>

<script>
import SendEmail from '../components/ForgotPassword/SendEmail.vue';
import ReceiveCode from '../components/ForgotPassword/ReceiveCode.vue';
import SetNewPassword from '../components/ForgotPassword/SetNewPassword.vue';
import AuthService from '../services/auth'
import { mapActions } from "vuex";

export default {
  components: {
    SendEmail,
    ReceiveCode,
    SetNewPassword
  },
  data(){
    return {
      component: '',
      email: ""
    }
  },
  methods: {
    ...mapActions({
      setIsLoading: "setIsLoading",
    }),
    ForgotPass() {
      if(!this.component) return SendEmail
      if(this.component === 'ReceiveCode') return ReceiveCode
      if(this.component === 'SetNewPassword') return SetNewPassword
    },
    setComponent(newComponent){
      if(newComponent.page==="ReceiveCode"){
        this.setIsLoading(true)
        AuthService.sendRecoverCode({email:newComponent.email}).then(()=>{
          this.email = newComponent.email
          this.component = "ReceiveCode"
          this.setIsLoading(false)
        }).catch(()=>{
          this.setIsLoading(false)
          this.$toast.error("Houve um erro ao tentar enviar o e-mail")
        })
      }else if(newComponent.page==="SetNewPassword"){
        this.setIsLoading(true)
        AuthService.confirmRecoverCode({email:this.email, code: newComponent.code}).then((response)=>{
          if(response.dados){
            this.component = "SetNewPassword"
          }else{
            this.$toast.error("Código inválido ou expirado");
          }
          this.setIsLoading(false)
        }).catch(()=>{
          this.$toast.error("Houve um erro ao tentar verificar o codigo.")
          this.setIsLoading(false)
        })
      }
      else
        this.component = newComponent;
    }
  }
}
</script>

<style>
.forgot-container {
  padding: 24px;
  height: 100vh;
}

@media screen and (min-width: 700px) {

  
  .forgot-container {
    width: 500px;
    height: 700px;
    border-radius: 15px;
    background: #fff;
  }
}

</style>